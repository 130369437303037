import Head from "next/head";
import { FunctionComponent } from "react";

import { BackgroundVideo, Button, Ticker } from "@/components";

const Index: FunctionComponent = () => (
  <>
    <Head>
      <title>BankX</title>
    </Head>
    <BackgroundVideo />
    <section className="column-3 column-span-7 row-1">
      <h1 className="balance">A Stablecoin for Individual Freedom</h1>
      <h2 className="balance">
        Mint Stablecoin and Earn{/* Using AI Automation*/}
      </h2>
      <nav>
        <Button animate href="https://app.bankx.io/">
          Launch BankX
        </Button>
      </nav>
    </section>
    <Ticker />
  </>
);

export default Index;
